<template>
  <div class="dashboard-content" data-app>
    <!-- Content -->
    <div class="card pt-3">
      <h3 class="text-center"> Estadisticas </h3>
      <div class="filters">
        <label>Filtrar por:</label>

        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="my-2">
          <v-row class="mx-0 align-items-center">
            <v-col cols="12" lg="3" md="4" sm="6">
              <v-text-field
                type="date"
                hide-details="auto"
                label="Fecha de Inicio"
                placeholder="Desde"
                v-model="filters.date.startAt"
              >
                <template v-slot:message> Fecha de Inicio </template></v-text-field
              >
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6">
              <v-text-field
                type="date"
                hide-details="auto"
                label="Fecha de Cierre"
                placeholder="Hasta"
                v-model="filters.date.endAt"
                ><template v-slot:label>
                  <span> Fecha de Culminación </span>
                </template></v-text-field
              >
            </v-col>
            <v-col cols="12" lg="1" md="2" sm="6">
              <multiselect
                style="min-width: 80px"
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                placeholder="Activa"
                v-model="filters.active"
                :options="activeOptions"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="#003a56"
                  style="height: 25px"
                  dark
                  x-small
                  class="m-1"
                  @click.prevent="
                    cleanFilters()
                    getCompaniesData()
                  "
                >
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Eliminar Filtros</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="#003a56"
                  style="height: 25px"
                  dark
                  x-small
                  class="m-1"
                  @click.prevent="getCompaniesData()"
                  ><v-icon dark> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>Filtrar</span>
            </v-tooltip>
          </v-row>
        </v-form>
      </div>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="items.length"
        multi-sort
        hide-default-footer
        fixed-header
        loading
        loading-text="El contenido está cargando... Por favor espere"
        class="elevation-1"
      >
        <template v-slot:[`item.country`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                :src="item.flag"
                max-height="25"
                max-width="50"
                v-bind="attrs"
                v-on="on"
              ></v-img>
            </template>
            <span> {{ item.country }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
  const today = new Date()
  /* eslint-disable */
  export default {
    data() {
      return {
        valid: true,
        loading: false,
        items: [],
        filters: {
          active: { id: 1, name: 'Activas' },
          date: {
            startAt: new Date(today.getFullYear(), today.getMonth(), 1)
              .toISOString()
              .split('T')[0],
            endAt: today.toISOString().split('T')[0],
          },
        },
        headers: [
          {
            text: 'Pais',
            align: 'start',
            sortable: false,
            value: 'country',
          },
          { text: 'Franquicias', value: 'companyTypes.0', align: 'center' },
          { text: 'Consultores', value: 'companyTypes.2', align: 'center' },
          { text: 'Proveedores', value: 'companyTypes.3', align: 'center' },
          { text: 'Asc. de Franquicias', value: 'companyTypes.1', align: 'center' },
          { text: 'Oferta Inmobiliaria', value: 'companyTypes.4', align: 'center' },
        ],
        activeOptions: [
          { id: null, name: 'Todas' },
          { id: 1, name: 'Activas' },
          { id: 0, name: 'Inactivas' },
        ],
      }
    },
    created() {
      this.getCompaniesData()
    },
    methods: {
      cleanFilters() {
        this.filters = {
          active: null,
          date: { startAt: '', endAt: '' },
        }
      },
      async getCompaniesData() {
        this.loading = true
        let queryParams = ''
        let dateString = ''
        let activeString = ''
        if (this.filters.date.startAt !== '' && this.filters.date.endAt !== '') {
          dateString =
            '&start_at=' +
            this.filters.date.startAt +
            '&end_at=' +
            this.filters.date.endAt
        }
        if (this.filters.active && this.filters.active.id === 1) {
          activeString = '&active=1'
        } else if (this.filters.active && this.filters.active.id === 0) {
          activeString = '&active=0'
        }
        queryParams = dateString + activeString
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'companies-type-by-contries?' +
              queryParams
          )
          .then((response) => {
            this.items = response.data
            this.loading = true
          })
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
</style>
