var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-content",attrs:{"data-app":""}},[_c('div',{staticClass:"card pt-3"},[_c('h3',{staticClass:"text-center"},[_vm._v(" Estadisticas ")]),_c('div',{staticClass:"filters"},[_c('label',[_vm._v("Filtrar por:")]),_c('v-form',{ref:"formCompany",staticClass:"my-2",staticStyle:{"width":"100%"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mx-0 align-items-center"},[_c('v-col',{attrs:{"cols":"12","lg":"3","md":"4","sm":"6"}},[_c('v-text-field',{attrs:{"type":"date","hide-details":"auto","label":"Fecha de Inicio","placeholder":"Desde"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v(" Fecha de Inicio ")]},proxy:true}]),model:{value:(_vm.filters.date.startAt),callback:function ($$v) {_vm.$set(_vm.filters.date, "startAt", $$v)},expression:"filters.date.startAt"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3","md":"4","sm":"6"}},[_c('v-text-field',{attrs:{"type":"date","hide-details":"auto","label":"Fecha de Cierre","placeholder":"Hasta"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(" Fecha de Culminación ")])]},proxy:true}]),model:{value:(_vm.filters.date.endAt),callback:function ($$v) {_vm.$set(_vm.filters.date, "endAt", $$v)},expression:"filters.date.endAt"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"1","md":"2","sm":"6"}},[_c('multiselect',{staticStyle:{"min-width":"80px"},attrs:{"track-by":"id","label":"name","deselect-label":"","select-label":"","placeholder":"Activa","options":_vm.activeOptions,"multiple":false,"close-on-select":true,"clear-on-select":false,"preserve-search":false},model:{value:(_vm.filters.active),callback:function ($$v) {_vm.$set(_vm.filters, "active", $$v)},expression:"filters.active"}})],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"m-1",staticStyle:{"height":"25px"},attrs:{"color":"#003a56","dark":"","x-small":""},on:{"click":function($event){$event.preventDefault();_vm.cleanFilters()
                  _vm.getCompaniesData()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1)]}}])},[_c('span',[_vm._v("Eliminar Filtros")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"m-1",staticStyle:{"height":"25px"},attrs:{"color":"#003a56","dark":"","x-small":""},on:{"click":function($event){$event.preventDefault();return _vm.getCompaniesData()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-filter ")])],1)]}}])},[_c('span',[_vm._v("Filtrar")])])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.items.length,"multi-sort":"","hide-default-footer":"","fixed-header":"","loading":"","loading-text":"El contenido está cargando... Por favor espere"},scopedSlots:_vm._u([{key:"item.country",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"src":item.flag,"max-height":"25","max-width":"50"}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.country))])])]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }